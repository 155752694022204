<template>
  <title-link-wrapper :to="{ name: 'Admins' }" :title="$t('components.singleAdmin')">
    <user-details
      :backLink="{ name: 'Admins' }"
      :toName="{ name: 'EditSysadmin', params: { userId: $route.params.userId } }"
    ></user-details>
  </title-link-wrapper>
</template>
<script>
import TitleLinkWrapper from '../../../../components/TitleLinkWrapper.vue';
import UserDetails from '../../../../components/UserDetails.vue';

export default { components: { UserDetails, TitleLinkWrapper }, name: 'SingleSysadmin' };
</script>
<style lang="scss" scoped></style>

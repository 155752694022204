<template>
  <title-link-wrapper
    :to="{ name: 'SingleSysadmin', params: { adminId: $route.params.adminId } }"
    :title="$t('components.editSysadmin')"
  >
    <edit-user
      :backLink="{ name: 'SingleSysadmin', params: { userId: $route.params.userId } }"
      :toDetails="{
        name: 'SingleSysadmin',
        params: { userId: $route.params.userId },
      }"
      :toManage="{
        name: 'Admins',
        params: { userId: $route.params.userId },
      }"
      :userRoles="userRoles"
    ></edit-user>
  </title-link-wrapper>
</template>
<script>
import TitleLinkWrapper from '../../../../components/TitleLinkWrapper.vue';
import EditUser from '../../../../components/EditUser.vue';
import { roles } from '../../../../static/roles';

export default {
  components: { EditUser, TitleLinkWrapper },
  name: 'EditAdmin',
  computed: {
    userRoles() {
      return [roles.SYSADMIN];
    },
  },
};
</script>
<style lang="scss" scoped></style>

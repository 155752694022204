<template>
  <v-container class="white pa-8">
    <v-row class="mb-6">
      <h2 class="text font-weight-regular">{{ $t('components.userDetails.title') }}</h2>
    </v-row>

    <v-row v-if="loading" class="mb-6">
      <v-progress-linear indeterminate></v-progress-linear>
    </v-row>
    <v-row v-else> <v-divider class="mb-6"></v-divider></v-row>
    <v-form v-if="!loading" v-model="isFormValid" ref="form">
      <v-row>
        <v-col class="pa-0" cols="5">
          <h2 class="text mb-3">{{ $t('helpers.firstName') }}</h2>
          <p>{{ user.firstName || '' }}</p>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="pa-0" cols="6">
          <h2 class="text mb-3">{{ $t('helpers.lastName') }}</h2>
          <p>{{ user.lastName || '' }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pa-0" cols="12" md="5">
          <h2 class="text mb-3">{{ $t('helpers.phoneNumber') }}</h2>
          <p>{{ user.phone || '' }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pa-0" cols="12" md="5">
          <h2 class="text mb-3">{{ $t('helpers.email') }}</h2>
          <p>{{ user.email || '' }}</p>
        </v-col>
      </v-row>
      <v-row> <v-divider class="mb-6"></v-divider></v-row>
      <v-row>
        <v-col class="pa-0" cols="12" md="5">
          <h2 class="text mb-3">{{ $t('helpers.typeOfRights') }}</h2>
          <p>{{ $t(`statics.roles.${user.role}`) || '' }}</p>
        </v-col>
      </v-row>
      <v-row class="mt-8">
        <primary-button :to="toName" outlined childclass="mt-2">{{
          $t('components.userDetails.editUser')
        }}</primary-button>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import PrimaryButton from './buttons/PrimaryButton.vue';
import { validators } from '../validators/Validators';
import UserService from '../services/UserService';
import { userDataComputed } from '../store/helper';
import { formMixin } from '../mixins/formMixin';

export default {
  name: 'UserDetails',
  mixins: [formMixin],
  data() {
    return {
      user: null,
      isFormValid: false,
      loading: true,
    };
  },
  props: ['toName', 'backLink'],
  components: { PrimaryButton },
  computed: {
    validator() {
      return validators;
    },
    ...userDataComputed,
  },
  async created() {
    const userId = this.$route.params.userId;
    try {
      this.loading = true;
      const res = await UserService.getUser(userId);
      this.user = res.data;
    } catch (err) {
      this.$toasted.global.error({ message: this.$i18n.t('components.userDetails.toasted') });
      this.$router.push(this.backLink);
    } finally {
      this.loading = false;
    }
  },
};
</script>
<style lang="scss" scoped></style>

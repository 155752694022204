<template>
  <title-link-wrapper :title="$t('components.addSysadmin')" :to="{ name: 'Admins' }">
    <add-user
      toDetails="Admins"
      :isSysadmin="true"
      :user-roles="userRoles"
      :add-func="addFunc"
    ></add-user>
  </title-link-wrapper>
</template>
<script>
import AddUser from '../../../../components/AddUser.vue';
import TitleLinkWrapper from '../../../../components/TitleLinkWrapper.vue';
import AuthService from '../../../../services/AuthService';
import { roles } from '../../../../static/roles';

export default {
  name: 'AddSysadmin',
  components: { AddUser, TitleLinkWrapper },
  data() {
    return {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      isFormValid: false,
      loading: false,
    };
  },
  computed: {
    userRoles() {
      return [roles.SYSADMIN];
    },
  },

  methods: {
    async addFunc(user) {
      return await AuthService.registerSysadmin(user);
    },
  },
};
</script>
<style lang="scss" scoped></style>

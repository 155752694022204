var render = function render(){var _vm=this,_c=_vm._self._c;return _c('title-link-wrapper',{attrs:{"to":{ name: 'SingleSysadmin', params: { adminId: _vm.$route.params.adminId } },"title":_vm.$t('components.editSysadmin')}},[_c('edit-user',{attrs:{"backLink":{ name: 'SingleSysadmin', params: { userId: _vm.$route.params.userId } },"toDetails":{
        name: 'SingleSysadmin',
        params: { userId: _vm.$route.params.userId },
      },"toManage":{
        name: 'Admins',
        params: { userId: _vm.$route.params.userId },
      },"userRoles":_vm.userRoles}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
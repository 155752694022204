<template>
  <v-container class="white pa-8">
    <v-row class="mb-6">
      <h2 class="text font-weight-regular">{{ $t('components.editUser.title') }}</h2>
    </v-row>
    <v-row v-if="loading" class="mb-6">
      <v-progress-linear indeterminate></v-progress-linear>
    </v-row>
    <v-row v-else class="mb-6"><v-divider></v-divider></v-row>

    <v-form v-if="!loading" v-model="isFormValid" ref="form">
      <v-row>
        <v-col class="pa-0" cols="5">
          <h2 class="text mb-3">{{ $t('helpers.firstName') }}*</h2>
          <v-text-field
            v-model="firstName"
            :rules="validator.requiredRules"
            outlined
            solo
          ></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="pa-0" cols="6">
          <h2 class="text mb-3">{{ $t('helpers.lastName') }}*</h2>
          <v-text-field
            v-model="lastName"
            :rules="validator.requiredRules"
            outlined
            solo
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pa-0" cols="12" md="5">
          <h2 class="text mb-3">{{ $t('helpers.phoneNumber') }}*</h2>
          <v-text-field v-model="phone" :rules="validator.phoneRules" outlined solo></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pa-0" cols="12" md="5">
          <h2 class="text mb-3">{{ $t('helpers.email') }}*</h2>
          <v-text-field v-model="email" :rules="validator.emailRules" outlined solo></v-text-field>
        </v-col>
      </v-row>
      <v-row> <v-divider class="mb-6"></v-divider></v-row>
      <v-row>
        <v-col class="pa-0" cols="12" md="5">
          <h2 class="text mb-3">{{ $t('helpers.typeOfRights') }}*</h2>
          <v-select
            v-model="userRole"
            :items="userRoles"
            :rules="validator.requiredRules"
            outlined
            solo
          >
            <template v-slot:selection="{ item }">
              {{ $t(`statics.roles.${item}`) }}
            </template>
            <template v-slot:item="{ item }">
              {{ $t(`statics.roles.${item}`) }}
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row class="justify-space-between">
        <primary-button childclass="mt-2" :onClick="editUser" :loading="loading" outlined>{{
          $t('components.editUser.saveData')
        }}</primary-button>
        <outlined-button childclass="mt-2" :onClick="deleteUser" outlined>{{
          $t('components.editUser.deleteUser')
        }}</outlined-button>
      </v-row>
    </v-form>
    <confirm-modal
      :open="openModal"
      :title="$t('components.editUser.modal.title')"
      :agree="$t('components.editUser.modal.agree')"
      :disagree="$t('components.editUser.modal.disagree')"
      color="error-primary"
      v-on:decline="decline"
      v-on:accept="accept"
      :loading="loadingDelete"
      >{{ $t('components.editUser.modal.text') }}</confirm-modal
    >
  </v-container>
</template>
<script>
import OutlinedButton from './buttons/OutlinedButton.vue';
import { validators } from '../validators/Validators';
import { roles } from '../static/roles';
import UserService from '../services/UserService';
import { userDataComputed } from '../store/helper';
import PrimaryButton from './buttons/PrimaryButton.vue';
import ConfirmModal from './modals/ConfirmModal.vue';
import { formMixin } from '../mixins/formMixin';

export default {
  name: 'EditUser',
  props: ['userRoles', 'toDetails', 'toManage'],
  mixins: [formMixin],
  data() {
    return {
      roles: Object.values(roles),
      userRole: roles.EMPLOYEE,
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      isFormValid: false,
      loading: false,
      openModal: false,
      loadingDelete: false,
    };
  },
  components: { OutlinedButton, PrimaryButton, ConfirmModal },
  methods: {
    isAdmin() {
      return this.userData.role === roles.SYSADMIN;
    },
    async accept() {
      const userId = this.$route.params.userId;

      try {
        this.loadingDelete = true;
        await UserService.deleteAccount(userId);
        this.openModal = false;
        this.$toasted.global.success({
          message: this.$i18n.t('components.editUser.acceptFunc.toastedSuccess'),
        });

        if (this.userData._id === userId) this.$store.commit('logout');
        else this.$router.push(this.toManage);
      } catch (err) {
        this.$toasted.global.error({
          message: this.$i18n.t('components.editUser.acceptFunc.toastedError'),
        });
        console.error(err);
        this.loadingDelete = false;
      } finally {
        this.loadingDelete = false;
      }
    },
    decline() {
      this.openModal = false;
    },

    async deleteUser() {
      this.openModal = true;
    },
    async editUser() {
      this.$refs.form.validate();

      if (this.isFormValid) {
        const user = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          phone: this.phone,
          companyId: this.userData.companyId,
          role: this.userRole,
        };

        try {
          this.loading = true;
          await UserService.update(this.$route.params.userId, user);

          this.$toasted.global.success({
            message: this.$i18n.t('components.editUser.editUserFunc.toastedSuccess'),
          });
          this.$router.push(this.toDetails);
        } catch (error) {
          this.$toasted.global.error({
            message: this.$i18n.t('components.editUser.editUserFunc.toastedError'),
          });
          console.error(error);
        } finally {
          this.loading = false;
        }
      }
    },
  },
  computed: {
    validator() {
      return validators;
    },
    ...userDataComputed,
  },
  async created() {
    const userId = this.$route.params.userId;
    try {
      this.loading = true;
      const res = await UserService.getUser(userId);
      const user = res.data;

      this.firstName = user.firstName;
      this.lastName = user.lastName;
      this.email = user.email;
      this.phone = user.phone;
      this.userRole = user.role;
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  },
};
</script>
<style lang="scss" scoped></style>

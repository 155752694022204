<template>
  <v-container class="white pa-8">
    <v-row class="mb-6">
      <h2 class="text font-weight-regular">{{ $t('components.addUser.componentTitle') }}</h2>
    </v-row>
    <v-row> <v-divider class="mb-6"></v-divider></v-row>
    <v-form v-model="isFormValid" ref="form">
      <v-row>
        <v-col class="pa-0" cols="12" sm="5">
          <h2 class="text mb-3">{{ $t('helpers.firstName') }}*</h2>
          <v-text-field
            v-model="firstName"
            :rules="validator.requiredRules"
            outlined
            solo
          ></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="pa-0" cols="12" sm="6">
          <h2 class="text mb-3">{{ $t('helpers.lastName') }}*</h2>
          <v-text-field
            v-model="lastName"
            :rules="validator.requiredRules"
            outlined
            solo
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pa-0" cols="12" sm="5">
          <h2 class="text mb-3">{{ $t('helpers.phoneNumber') }}*</h2>
          <v-text-field v-model="phone" :rules="validator.phoneRules" outlined solo></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="pa-0" cols="12" sm="6">
          <h2 class="text mb-3">{{ $t('helpers.email') }}*</h2>
          <v-text-field v-model="email" :rules="validator.emailRules" outlined solo></v-text-field>
        </v-col>
      </v-row>
      <v-row> <v-divider class="mb-6"></v-divider></v-row>
      <v-row>
        <v-col class="pa-0" cols="12" sm="12" md="5">
          <h2 class="text mb-3">{{ $t('helpers.typeOfRights') }}*</h2>
          <v-select
            v-model="userRole"
            :items="userRoles"
            :rules="validator.requiredRules"
            :label="$t('helpers.typeOfRights')"
            outlined
            solo
          >
            <template v-slot:selection="{ item }">
              {{ $t(`statics.roles.${item}`) }}
            </template>
            <template v-slot:item="{ item }">
              {{ $t(`statics.roles.${item}`) }}
            </template></v-select
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pa-0" cols="12" sm="12" md="5">
          <outlined-button :onClick="addUser" :loading="loading" outlined>{{
            $t('components.addUser.addUser')
          }}</outlined-button>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import OutlinedButton from './buttons/OutlinedButton.vue';
import { validators } from '../validators/Validators';
import { roles } from '../static/roles';
import { userDataComputed } from '../store/helper';
import { formMixin } from '../mixins/formMixin';

export default {
  name: 'AddUser',
  props: ['userRoles', 'toDetails', 'isSysadmin', 'addFunc'],
  mixins: [formMixin],
  data() {
    return {
      userRole: roles.EMPLOYEE,
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      isFormValid: false,
      loading: false,
    };
  },
  components: { OutlinedButton },
  methods: {
    async addUser() {
      this.$refs.form.validate();

      if (this.isFormValid) {
        const user = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          phone: this.phone,
          role: this.userRole,
        };

        if (this.isSysadmin) {
          user.role = roles.SYSADMIN;
        } else {
          user.companyId = this.userData.companyId;
        }

        try {
          this.loading = true;
          const response = await this.addFunc(user);

          this.$router.push({ name: this.toDetails, params: { userId: response.user._id } });
          this.$toasted.global.success({
            message: this.$i18n.t('components.addUser.toasted'),
          });
        } catch (error) {
          console.error(error);
        } finally {
          this.loading = false;
        }
      }
    },
  },
  computed: {
    ...userDataComputed,
    validator() {
      return validators;
    },
  },
};
</script>
<style lang="scss" scoped></style>
